<template>
	<section id="editor-wrapper">
		<div id="editor"></div>
	</section>
</template>

<script>
import Quill from 'quill';

export default {
	data() {
		return {
			msg: 'Sportlander editor',
			editor: null,
			entryContent: null,
			valueIsPassed: false,
		};
	},
	props: ['contentInDelta'],
	emits: ['written-content'],
	mounted() {
		this.editor = new Quill('#editor', {
			modules: {
				toolbar: [
					[{ header: [1, 2, 3, 4, false] }],
					['bold', 'italic', 'underline', 'strike'],
					[
						{ list: 'ordered' },
						{ list: 'bullet' },
						{ indent: '-1' },
						{ indent: '+1' },
					],
					['direction', { align: [] }],
					['link', 'video'], // 'image'
				],
			},
			placeholder: `Kezdd el írni a bejegyzést, például "Az utánpótlásképzés legnépszerűbb eszköze, a SmartGoals interaktív okosbóják, az edzések egy új dimenzióját nyitja meg mind a szakemberek mind a játékosok előtt. Az Manchester United, Ajax Amsterdam vagy PSV Eindhoven által alkalmazott holland innováció, amelett hogy kitűnően stimulálja a kognitív képességeket, a játékosok agilitását, vagy akár taktikai felkészültségét, kíváló mérési eszköz is egyben..."`,
			theme: 'snow',
			formats: [
				'bold',
				'underline',
				'header',
				'italic',
				'strike',
				'font',
				'code',
				'link',
				'size',
				'blockquote',
				'indent',
				'list',
				'align',
				'code-block',
				'image',
				'video',
			],
		});

		// We will add the update event here
		this.editor.on('text-change', (delta, oldDelta, source) => {
			this.entryContent = this.editor.getContents();
			// this.$emit('written-content', this.entryContent);
		});

		console.log('QUILL EDITOR INSTANCE IS CREATED!')
			if (this.contentInDelta && !this.valueIsPassed) {
				this.editor.setContents(JSON.parse(this.contentInDelta), 'api');
				this.valueIsPassed = true;
			}
	},
	unmounted() {
	console.log('this is the unmounted hook')
	}
};
</script>

<style>
@import url('./../assets/quill.snow.css');
#editor {
	height: 20rem;
}
</style>
