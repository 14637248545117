<template>
	<div>
		<dashboard-component></dashboard-component>
		<section id="entries-section">
			<div class="container">
				<div>
					<nav
						style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
						aria-label="breadcrumb"
					>
						<ol class="breadcrumb">
							<li class="breadcrumb-item">
								<a href="#">Dashboard</a>
							</li>
							<li
								class="breadcrumb-item active"
								aria-current="page"
							>
								Entries
							</li>
						</ol>
					</nav>
				</div>
				<h1>Entries</h1>
				<hr />
				<div class="row">
					<div class="col mb-3">
						<a
							class="btn btn-primary"
							:href="`${loadLandingPageUrl}/blog/posts`"
							target="_blank"
							>See published entries</a
						>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<div
							class="modal fade"
							id="confirmDeletionModal"
							tabindex="-1"
							aria-labelledby="confirmDeletionModalLabel"
							aria-hidden="true"
						>
							<div class="modal-dialog">
								<div class="modal-content">
									<div class="modal-header">
										<h5
											class="modal-title"
											id="confirmDeletionModal"
										>
											Confirm
										</h5>
										<button
											type="button"
											class="btn-close"
											data-bs-dismiss="modal"
											aria-label="Bezárás"
										></button>
									</div>
									<div class="modal-body">
										<div
											class="d-flex flex-column align-items-center justify-content-center"
										>
											<p class="lead text-center">
												Do you really want to delete this entry?
											</p>

											<BIconXOctagonFill
												class="warningLogo"
												fill="#dc3545"
											/>
										</div>
									</div>
									<div class="modal-footer">
										<button
											type="button"
											class="btn btn-secondary"
											data-bs-dismiss="modal"
										>
											Cancel
										</button>
										<button
											@click="
												deleteEntry(
													selectedEntryForDeletion
												)
											"
											type="button"
											class="btn btn-danger"
											data-bs-dismiss="modal"
										>
											Delete
										</button>
									</div>
								</div>
							</div>
						</div>
						<div
							class="modal fade"
							id="editModal"
							data-bs-backdrop="static"
							data-bs-keyboard="false"
							tabindex="-1"
							aria-labelledby="editModalLabel"
							aria-hidden="true"
						>
							<div
								class="modal-dialog modal-dialog-scrollable modal-xl modal-fullscreen-sm-down"
							>
								<div class="modal-content">
									<div class="modal-header">
										<h5 class="modal-title" id="editModal">
											Edit blog entry
										</h5>
										<button
											type="button"
											class="btn-close"
											data-bs-dismiss="modal"
											aria-label="Bezárás"
										></button>
									</div>
									<div class="modal-body">
										<!-- Modal body content starts here for editing a blog post -->
										<div v-if="!checkIfPostLoaded">
											Loading...
											{{
												JSON.stringify(
													checkIfPostLoaded
												)
											}}
										</div>
										<div v-else>
											<div class="container-fluid">
												<div class="row">
													<div class="col-12">
														<div class="mb-3">
															<label
																for="entryTitle"
																class="form-label"
																>Title
															</label>
															<input
																v-model="
																	singlePostInstance.title
																"
																type="text"
																class="form-control"
																id="entryTitle"
																placeholder="Az utánpótlásképzés legnépszerűbb eszköze, a SmartGoals..."
															/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-6">
														<div class="mb-3">
															<label
																for="entryCategory"
																class="form-label"
																>Category</label
															>
															<input
																@keyup.enter="
																	useCategory(
																		$event
																	)
																"
																type="text"
																class="form-control"
																id="entryCategory"
																placeholder="Sport"
															/>
														</div>
													</div>
													<div class="col-6">
														<div class="mb-3">
															<label
																for="entryLabels"
																class="form-label"
																>Labels</label
															>
															<input
																@keyup.enter="
																	useLabel(
																		$event
																	)
																"
																type="text"
																class="form-control"
																id="entryLabels"
																placeholder="innováció, labdarúgás, akadémia"
															/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-6">
														<span
															class="badge rounded-pill bg-secondary mx-1 p-2"
															>{{
																singlePostInstance.category
															}}
															Category</span
														>
													</div>
													<div class="col-6">
														<span
															v-for="(label,
															index) of singlePostInstance.labels"
															@click="
																removeLabel(
																	index
																)
															"
															:key="index"
															class="badge rounded-pill bg-secondary mx-1 p-2"
															>{{ label }}</span
														>
													</div>
												</div>
												<div class="row">
													<div class="col-12">
														<label
															for="entryContent"
															class="form-label"
															>Content</label
														>
														<editor-component
															id="entryContent"
															:content-in-delta="
																singlePostInstance.content
															"
															@written-content="
																processWrittenContent(
																	$event
																)
															"
														></editor-component>
													</div>
												</div>
											</div>
											<!-- Model body content ends here for editing a blog post -->
										</div>
									</div>
									<div class="modal-footer">
										<button
											type="button"
											class="btn btn-secondary"
											data-bs-dismiss="modal"
										>
											Cancel
										</button>
										<button
											@click="updatePostDetails()"
											type="button"
											class="btn btn-success"
											data-bs-dismiss="modal"
										>
											Update
										</button>
									</div>
								</div>
							</div>
						</div>
						<div
							class="row py-3 entry-list"
							v-for="entry in listAllEntries"
							:key="entry"
						>
							<div class="col-12 col-lg-8 order-lg-first">
								<h5>
									{{ entry.title.substring(0, 100) }}
								</h5>
								<a
									class="visit-blog-post-link"
									:href="
										`${loadLandingPageUrl}/blog/posts/${entry.url}`
									"
									target="_blank"
									>See blog entry</a
								>
								<div>
									Category:
									<span class="px-2">{{
										entry.category
									}}</span>
								</div>
								<div>
									Labels:
									<span class="px-2">{{
										entry.labels.join(', ')
									}}</span>
								</div>
							</div>
							<div
								class="col-12 col-lg-4 order-first order-lg-last d-flex align-items-center justify-content-lg-end"
							>
								<!-- <button
									@click="storyEntryIdForEdit(entry.id)"
									type="button"
									class="btn btn-primary me-2 mb-3"
									data-bs-toggle="modal"
									data-bs-target="#editModal"
								>
									Szerkesztés
								</button> -->
								<router-link
									:to="`/admin/dashboard/edit/${entry.id}`"
									class="btn btn-primary me-2 mb-3"
									>Edit</router-link
								>
								<button
									@click="storeEntryIdForDeletion(entry.id)"
									type="button"
									class="btn btn-danger me-2 mb-3"
									data-bs-toggle="modal"
									data-bs-target="#confirmDeletionModal"
								>
									Delete
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div class="container-fluid">
			<div class="row">
				<footer-component></footer-component>
			</div>
		</div>
	</div>
</template>

<script>
import {
	BIconXOctagonFill,
	BIconBookmarkStar,
	BIconTags,
} from 'bootstrap-icons-vue';

export default {
	setup() {},
	components: {
		BIconXOctagonFill,
		BIconBookmarkStar,
		BIconTags,
	},
	data() {
		return {
			listOfEntries: null,
			selectedEntryForDeletion: null,
			selectedEntryForEdit: null,
			singlePostInstance: null,
			entryTitle: '',
			entryLabels: 'Még nem választottál ki címkéket!',
			writtenContent: null,
		};
	},

	methods: {
		storeEntryIdForDeletion(entryId) {
			this.selectedEntryForDeletion = entryId;
		},
		storyEntryIdForEdit(entryId) {
			this.selectedEntryForEdit = entryId;
			this.updateEntry(entryId);
		},
		deleteEntry(entryId) {
			this.$store.dispatch('deleteEntry', entryId);
		},
		async updateEntry(entryId) {
			this.loading = true;
			const result = await this.$store.dispatch(
				'listSingleEntry',
				entryId
			);
			this.singlePostInstance = this.$store.getters.listSingleEntry;
		},
		useCategory(event) {
			this.singlePostInstance.category = event.target.value;
		},
		useLabel(event) {
			if (event.target.value != '') {
				this.singlePostInstance.labels.push(event.target.value);
				event.target.value = '';
			}
		},
		removeLabel(labelIndex) {
			this.singlePostInstance.labels.splice(labelIndex, 1);
		},
		processWrittenContent(event) {
			// Jelenleg minden keystroke esetén emittálunk a child-ból, ami performance szempontból hibás.
			console.log(event);
			this.singlePostInstance.content = JSON.stringify(event);
		},
		async updatePostDetails() {
			const result = await this.$store.dispatch(
				'updateEntry',
				this.singlePostInstance
			);
		},
	},
	mounted() {
		this.$store.dispatch('listAllEntries');
	},
	beforeUnmount() {
		console.log(this.selectedEntryForEdit);
	},
	computed: {
		listAllEntries() {
			return this.$store.getters.listAllEntries;
		},
		checkIfPostLoaded() {
			return this.singlePostInstance;
		},
		loadLandingPageUrl() {
			return process.env.VUE_APP_LANDING_URL;
		},
	},
};
</script>

<style scoped>
.warningLogo {
	width: 3rem;
	height: 3rem;
}
.btn.btn-primary {
	background-color: #131132;
	border: 0px;
}
.visit-blog-post-link:any-link {
	color: #e6627e;
	text-decoration: none;
}
.entry-list:nth-child(2n) {
	background-color: #dadada9d;
	border-radius: 15px;
}
#entries-section {
	min-height: 65vh;
}
</style>
