<template>
	<div class="container">
		<div class="row row-cols-1 row-cols-md-3 g-4">
			<div v-for="post of listOfPosts" :key="post.id" class="col">
				<div class="card h-100">
					<img
						src="../assets/default-blog-img.svg"
						class="card-img-top"
						alt="..."
					/>
					<div class="card-header">
						<a :href="'/blog/categories/' + post.category">{{
							post.category
						}}</a>
					</div>
					<div class="card-body">
						<h5 class="card-title">
							{{ post.title }}
						</h5>
						<p class="card-text">
							This is a longer card with supporting text below as
							a natural lead-in to additional content. This
							content is a little bit longer.
						</p>
					</div>
					<div class="card-footer">
						df
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	setup() {},
	data() {
		return {
			posts: null,
		};
	},
	mounted() {
		this.$store.dispatch('listAllEntries');
	},
	computed: {
		listOfPosts() {
			return this.$store.getters.listAllEntries;
		},
		capitalizeCategory(lowerCasePostCategory) {
			return (
				lowerCasePostCategory.charAt(0).toUpperCase() +
				lowerCasePostCategory.slice(1)
			);
		},
	},
};
</script>

<style scoped>
.card-header {
	background-color: white;
	border: 0px;
}
a:any-link {
	color: #e6627e;
	text-decoration: none;
}
</style>
