<template>
	<div>
		<header-component></header-component>
		<div class="container">
			<section>
				<nav class="my-4">
					<ul class="nav nav-pills">
						<li class="nav-item">
							<router-link
								class="nav-link"
								:class="{
									active: currentTabComponent == 'new',
								}"
								to="/admin/dashboard/new"
								>New entry</router-link
							>
						</li>
						<li class="nav-item">
							<router-link
								class="nav-link"
								:class="{
									active: currentTabComponent == 'entries',
								}"
								@click="currentTab = 'entries'"
								to="/admin/dashboard/entries"
								>Entries</router-link
							>
						</li>
						<li class="nav-item">
							<router-link
								class="nav-link"
								:class="{
									active: currentTabComponent == 'authors',
								}"
								@click="currentTab = 'authors'"
								to="/admin/dashboard/authors"
								>Authors</router-link
							>
						</li>
					</ul>
				</nav>

				<!-- <component
					:toEditPost="toEditPost"
					:is="currentTabComponent"
				></component> -->
			</section>
		</div>
		<!-- <div class="container-fluid">
			<div class="row">
				<footer-component></footer-component>
			</div>
		</div> -->
	</div>
</template>

<script>
export default {
	setup() {},
	data() {
		return {
			currentTab: 'entries',
			tabs: ['New', 'Entries', 'Authors'],
			selectedPostToEdit: null,
		};
	},
	mounted() {
		console.log(this.$route.path);
		if (this.$route.path.includes('new')) {
			this.currentTab = 'new';
		} else if (this.$route.path.includes('entries')) {
			this.currentTab = 'entries';
		} else if (this.$route.path.includes('authors')) {
			this.currentTab = 'authors';
		}
	},
	methods: {
		setCurrentTab(value) {
			this.currentTab = value;
		},
	},
	computed: {
		currentTabComponent() {
			return this.currentTab;
		},
		toEditPost() {
			return this.selectedPostToEdit;
		},
	},
};
</script>

<style scoped>
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	background-color: #131132;
}
.nav-link {
	color: #131132;
	cursor: pointer;
}
.nav-link:hover {
	color: #e6627e;
}
</style>
