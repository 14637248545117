import { createApp } from 'vue';
import App from './App.vue';

import router from './assets/router.js';
import store from './store/index.js';

import HeaderComponent from './components/header.vue';
import FooterComponent from './components/footer.vue';
import DashboardComponent from './pages/dashboard.vue';
import EntriesPage from './pages/entries.vue';
import AuthorsPage from './pages/authors.vue';
import NewPage from './pages/new.vue';
import EditPage from './pages/edit.vue';
import EditorComponent from './components/editor.vue';

// Bootstrap related cuccok
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/main.css';

const app = createApp(App);

app.use(store);
app.use(router);

app.component('header-component', HeaderComponent);
app.component('footer-component', FooterComponent);
app.component('dashboard-component', DashboardComponent)
app.component('entries-page', EntriesPage);
app.component('new-page', NewPage);
app.component('edit-page', EditPage);
app.component('authors-page', AuthorsPage);
app.component('editor-component', EditorComponent);

app.mount('#app');
