<template>
	<footer class="pb-4 mb-0 d-flex align-items-end justify-content-center">
		<div class="row d-flex">
			<div
				class="col-12 mb-3 d-flex flex-column align-items-center justify-content-center"
			>
				<img
					src="../assets/sm-logo.png"
					alt=""
					width="150"
					class="d-inline-block align-text-center"
				/>
				<span id="sportlander-footer-title" class="h4 mt-4"
					>SmartGoals</span
				>
			</div>
			<div class="col-12 d-flex justify-content-center">
				<span class="small text-muted"
					>&lt;blogEngine/&gt;</span
				>
			</div>
		</div>
	</footer>
</template>

<style>
footer {
	background-image: url('../assets/footer.svg');
	background-position-y: top;
	background-size: cover;
	width: 100%;
	margin: 0;
	background-repeat: no-repeat;
	background-color: #131132;
	height: 15rem;
}
#sportlander-footer-title {
	color: #fff;
}
</style>
