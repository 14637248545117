<template>
	<div>
		<dashboard-component></dashboard-component>
		<section id="new-section">
			<div class="container">
				<Form v-slot="{ errors, meta }">
					<div>
						<nav
							style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
							aria-label="breadcrumb"
						>
							<ol class="breadcrumb">
								<li class="breadcrumb-item">
									<a href="#">Dashboard</a>
								</li>
								<li
									class="breadcrumb-item active"
									aria-current="page"
								>
									New entry
								</li>
							</ol>
						</nav>
					</div>
					<h1>New entry</h1>
					<hr />
					<div class="row">
						<div class="col-12">
							<div class="mb-3">
								<label for="entryTitle" class="form-label"
									>Title</label
								>
								<Field
									v-model="entryTitle"
									type="text"
									class="form-control"
									id="entryTitle"
									name="entryTitle"
									:rules="entryTitleRules"
									:validateOnChange="true"
									:class="{
										'is-valid':
											!errors.entryTitle && entryTitle,
										'is-invalid': errors.entryTitle,
									}"
									placeholder="Az utánpótlásképzés legnépszerűbb eszköze, a SmartGoals..."
								/>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-6">
							<div class="mb-3">
								<label for="entryCategory" class="form-label"
									>Category</label
								>
								<Field
									@keyup.enter="useCategory($event)"
									type="text"
									class="form-control"
									id="entryCategory"
									name="entryCategory"
									:rules="entryCategoryRules"
									:class="{
										'is-valid':
											!errors.entryCategory &&
											entryCategoryValue,
										'is-invalid': errors.entryCategory,
									}"
									placeholder="Sport"
								/>
							</div>
						</div>
						<div class="col-6">
							<div class="mb-3">
								<label for="entryLabels" class="form-label"
									>Labels</label
								>
								<Field
									@keyup.enter="useLabel($event)"
									type="text"
									class="form-control"
									id="entryLabels"
									name="entryLabels"
									:rules="entryLabelsRules"
									:class="{
										'is-valid':
											!errors.entryLabels &&
											entryLabelsArray.length,
										'is-invalid': errors.entryLabels,
									}"
									placeholder="innovation, football, academy"
								/>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-6">
							<span
								class="badge rounded-pill bg-secondary mx-1 p-2"
								>{{ entryCategoryValue }}</span
							>
						</div>
						<div class="col-6">
							<span
								v-for="(label, index) of entryLabelsArray"
								@click="removeLabel(index)"
								:key="index"
								class="badge rounded-pill bg-secondary mx-1 p-2"
								>{{ label }}</span
							>
						</div>
					</div>
					<div class="row mb-3">
						<div class="col">
							<label for="entryImage" class="form-label"
								>Picture URL</label
							>
							<Field
								v-model="entryImage"
								type="text"
								class="form-control"
								id="entryImage"
								name="entryImage"
								placeholder="Copy the URL of the header image."
							/>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<label for="entryContent" class="form-label"
								>Content</label
							>

							<!-- eslint-disable -->
							<QuillEditor
								contentType="html"
								v-model:content="writtenContent"
								:options="editorOptions"
								toolbar="full"
								theme="snow"
							/>
							<!-- eslint-enable -->
						</div>
					</div>

					<div class="row mt-5">
						<div class="col-12">
							<div class="alert alert-secondary" role="alert">
								Before clicking the Publish button, make sure that you added a category and at least one label!
							</div>
						</div>
						<div class="col-12">
							<div
								v-if="errorsExists"
								class="alert alert-danger"
								role="alert"
							>
								<p>
									One or more input fields are empty.
								</p>
								<p>
									Make sure that the title has at least 15 chars.
								</p>
								<p>
									Make sure that you pressed ENTER after typing the values for category and labels.
								</p>
							</div>
						</div>
						<div class="col-12">
							<section>
								<button
									@click="publishEntry(errors)"
									type="button"
									class="btn btn-primary btn-lg mt-5"
									:disabled="!(meta.valid && meta.dirty)"
								>
									Publish!
								</button>
							</section>
						</div>
					</div>
				</Form>
			</div>
		</section>
		<div class="container-fluid">
			<div class="row">
				<footer-component></footer-component>
			</div>
		</div>
	</div>
</template>

<script>
import { Field, Form } from 'vee-validate';
import * as yup from 'yup';

import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
	setup() {},
	components: {
		Field,
		Form,
		QuillEditor,
	},
	data() {
		return {
			entryTitle: null,
			entryCategory: null,
			entryLabels: null,
			entryCategoryValue: null,
			entryLabelsArray: [],
			entryImage: null,
			writtenContent: '',
			entryTitleRules: yup
				.string()
				.required()
				.min(10),
			entryCategoryRules: yup.string().required(),
			entryLabelsRules: yup.string().required(),
			editorOptions: {},
			errorsExists: false,
		};
	},
	methods: {
		useCategory(event) {
			this.entryCategoryValue = event.target.value;
		},
		useLabel(event) {
			if (event.target.value != '') {
				this.entryLabelsArray.push(event.target.value);
			}
			console.log(this.entryLabelsArray.length);
		},
		removeLabel(labelIndex) {
			this.entryLabelsArray.splice(labelIndex, 1);
		},
		processWrittenContent(event) {
			// Jelenleg minden keystroke esetén emittálunk a child-ból, ami performance szempontból hibás.
			this.writtenContent = JSON.stringify(event);
		},
		publishEntry(errors) {
			const entryDetails = {
				title: this.entryTitle,
				category: this.entryCategoryValue,
				labels: this.entryLabelsArray.join(),
				image: this.entryImage,
				admin: 1,
				content: this.writtenContent,
				authorId: this.$store.getters.loggedInAuthor.id,
			};
			if (
				entryDetails.title.length < 15 ||
				entryDetails.category.length < 1 ||
				entryDetails.labels.length < 1
			) {
				this.errorsExists = true;
			} else {
				this.$store.dispatch('publishEntry', entryDetails);
			}
		},
	},
};
</script>
<style>
ol > .breadcrumb-item > a {
	color: #e6627e !important;
}
.ql-toolbar.ql-snow {
	border-bottom: none !important;
	border: 1px solid 131132;
	border-radius: 10px 10px 0 0;
}
.ql-container.ql-snow {
	padding: 0.5rem;
	border: 1px solid 131132 !important;
	border-radius: 0 0 10px 10px;
}
#entryContent {
	border: 1px solid #ccc;
	border-radius: 10px;
}
.btn.btn-primary.btn-lg {
	background-color: #131132;
	border: 0px;
}
#new-section {
	min-height: 65vh;
}
</style>
