import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index';

import LoginPage from '../pages/login.vue';
import DashboardPage from '../pages/dashboard.vue';
import PostsPage from '../pages/posts.vue';
import PostPage from '../pages/post.vue';
import NewPage from '../pages/new.vue';
import EntriesPage from '../pages/entries.vue';
import AuthorsPage from '../pages/authors.vue';
import EditPage from '../pages/edit.vue';

const router = createRouter({
	history: createWebHistory(
		process.env.NODE_ENV === 'production' ? '/admin/' : ''
	),
	routes: [
		{
			path: '/',
			redirect: '/admin',
		},
		{
			path: '/admin',
			redirect: '/admin/login',
		},
		{
			path: '/admin/login',
			component: LoginPage,
		},
		{
			path: '/admin/dashboard',
			redirect: '/admin/dashboard/entries',
			meta: {
				authIsRequired: true,
			},
		},
		{
			path: '/admin/dashboard/new',
			component: NewPage,
			meta: {
				authIsRequired: true,
			},
		},
		{
			path: '/admin/dashboard/entries',
			component: EntriesPage,
			meta: {
				authIsRequired: true,
			},
		},
		{
			path: '/admin/dashboard/authors',
			component: AuthorsPage,
			meta: {
				authIsRequired: true,
			},
		},
		{
			path: '/admin/dashboard/edit/:entryId',
			component: EditPage,
			meta: {
				authIsRequired: true,
			},
		},
		// {
		// 	path: '/blog/posts',
		// 	component: PostsPage,
		// },
		// {
		// 	path: '/blog/posts/:entryUrl',
		// 	component: PostPage,
		// },
	],
	scrollBehavior(to) {
		if (to.fullPath.includes('dashboard')) {
			return {
				top: 0,
			};
		}
		if (to) {
			return {
				behavior: 'smooth',
			};
		}
	},
});

router.beforeEach((to, from, next) => {
	const authIsRequired = to.matched.some(
		(record) => record.meta.authIsRequired
	);

	const isLoggedIn = store.getters.checkToken;

	if ((to.path === '/admin' || to.path === '/admin/login') && isLoggedIn) {
		return next('/admin/dashboard');
	}

	if (authIsRequired && !isLoggedIn) {
		return next('/admin/login');
	}
	next();
});

export default router;
