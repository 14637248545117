<template>
	<div
		v-if="isLoading"
		class="d-flex justify-content-center align-items-center vh-100"
	>
		<div
			class="spinner-border d-flex"
			style="width: 5rem; height: 5rem;"
			role="status"
		>
			<span class="visually-hidden">Loading...</span>
		</div>
	</div>
	<div v-else>
		<div class="container">
			<div class="row">
				<div
					class=" col-lg-1 col-md-1 col-sm-12 order-last order-md-first order-lg-first d-none d-sm-none d-md-block"
				>
					<div id="sidebar">
						<div
							id="sidebar-content"
							class="d-flex flex-column align-items-center justify-content-center"
						>
							<img
								src="../assets/arrow-left.svg"
								class="img-fluid"
								alt="Vissza az előző oldalra"
							/>
							<h4>Back</h4>
						</div>
					</div>
				</div>
				<div
					class="col-lg-11 col-md-11 col-sm-12 order-sm-first order-md-first order-lg-first"
				>
					<div class="row mt-5">
						<div class="col">
							<span class="h2">{{ showEntry.title }}</span>
						</div>
					</div>
					<div class="row mt-4">
						<!-- <div class="col d-flex flex-row">
							<div id="author" class="d-flex pr-2">
								Author
							</div>
							<div id="date" class="d-flex px-2">
								{{ formatDate }}
							</div>
							<div id="category" class="d-flex px-2">
								{{ showEntry.category }} - Az általános olvasási
								idő
								{{ showStatistics.text }}
							</div>
						</div> -->
						<div
							class="col-sm-12 col-md-6 d-flex align-items-center"
						>
							<div id="author-avatar-small">
								<img
									src="../assets/Avatar.jpeg"
									class="card-img-top"
									alt="..."
								/>
							</div>
							<div class="p-2">
								<span>Gyuró Krisztián</span>
							</div>
						</div>
						<div class="col-sm-12 col-md-6">
							<div class="mt-2">{{ formatDate }}</div>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<div class="mt-2">
								General reading time is
								{{ Math.round(showStatistics.minutes) }} minutes.
							</div>
						</div>
					</div>
					<!-- This row holds the main img for the blog body -->
					<div class="row mt-4">
						<img
							src="../assets/default-blog-img.svg"
							class="card-img-top"
							alt="..."
						/>
					</div>
					<!-- This row contains the actual Sportlander blog body -->
					<div class="row mt-4">
						<div id="blog-body" class="col">
							<span v-html="showEntry.content"></span>
						</div>
					</div>
					<!-- Footer for blog body -->
					<div class="row my-4">
						<div class="col">
							<div class="small text-center">
								Do you like the content ? Check out the category
								<a
									:href="
										`/blog/categories/${showEntry.category}`
									"
								>
									<span
										class="badge rounded-pill bg-secondary"
										>{{ showEntry.category }}</span
									>
								</a>
								or the
								<span
									v-for="label of showEntry.labels"
									:key="label"
								>
									<a :href="`/blog/labels/${label.trim()}`">
										<span
											class="badge rounded-pill bg-secondary my-1"
											>{{ label }}</span
										> </a
									>&nbsp;
								</span>
								labels.
							</div>
						</div>
					</div>
					<!-- This row contains the about the author section -->
					<div
						class="row my-5 d-flex align-items-center justify-content-center justify-content-md-start"
					>
						<div
							id="author-avatar"
							class="col-sm-12 col-md-4 col-lg-3 d-flex"
						>
							<img
								src="../assets/Avatar.jpeg"
								class="card-img-top"
								alt="..."
							/>
						</div>
						<div
							class="col-sm-12 col-md-8 col-lg-9 d-flex align-items-center justify-content-center justify-content-md-start"
						>
							<div
								class="d-flex flex-column align-items-center align-items-md-start"
							>
								<div>
									<span class="h4 py-2">Gyuró Krisztián</span>
								</div>
								<div>
									<p class="lead text-center">
										Sportinovációs szakember, bla bla bla
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	setup() {},
	data() {
		return {
			loading: true,
			entryUrl: null,
			post: null,
		};
	},
	mounted() {
		this.entryUrl = this.$route.params.entryUrl;
		this.getSingleEntry();
	},
	methods: {
		async getSingleEntry() {
			await this.$store.dispatch('listSingleEntryByUrl', this.entryUrl);
			this.post = await this.$store.getters.listSingleEntryByUrl;

			setTimeout(() => {
				this.loading = false;
			}, 200);
		},
	},
	computed: {
		showEntry() {
			return this.post.entry;
		},
		showStatistics() {
			return this.post.readingStatistics;
		},
		isLoading() {
			return this.loading;
		},
		formatDate() {
			const date = new Date(this.showEntry.updatedAt);
			return date.toLocaleDateString('hu-HU');
		},
	},
};
</script>
<style scoped>
#blog-body {
	text-align: justify;
}
#sidebar {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	position: sticky;	
	top: 0rem;
	width: 100%;
}
#author-avatar {
	width: 10rem;
}
#author-avatar-small {
	width: 3rem;
}
#author-avatar-small > img {
	border-radius: 50%;
}
#author-avatar > img {
	border-radius: 50%;
}
#sidebar-content {
	position: relative;
	top: 50%;
	height: 100vh;
}
#sidebar-content > img {
	width: 3rem;
}
a:any-link {
	color: #e6627e;
	text-decoration: none;
}
</style>
