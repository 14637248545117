import { createStore } from 'vuex';
import router from '../assets/router.js';

const store = createStore({
	modules: {},
	state() {
		return {
			listAllEntries: [],
			listByCategory: [],
			listSingleEntry: null,
			listAuthors: null,
			listSingleEntryWithHtml: null,
			listSingleEntryByUrl: null,
			token: null,
			loggedInAuthor: {
				id: null,
				name: localStorage.getItem('author') || null,
				avatar: null,
			},
		};
	},
	getters: {
		listAllEntries(state) {
			return state.listAllEntries;
		},
		listByCategory(state) {
			return state.listByCategory;
		},
		listSingleEntry(state) {
			return state.listSingleEntry;
		},
		listAuthors(state) {
			return state.listAuthors;
		},
		listSingleEntryWithHtml(state) {
			return state.listSingleEntryWithHtml;
		},
		listSingleEntryByUrl(state) {
			return state.listSingleEntryByUrl;
		},
		checkToken(state) {
			const token = localStorage.getItem('token');
			if (token) {
				state.token = token;
				return token;
			}
			return null;
		},
		loggedInAuthor(state) {
			return state.loggedInAuthor;
		},
	},
	mutations: {
		listAllEntries(state, payload) {
			state.listAllEntries = payload.reverse();
		},
		listByCategory(state, payload) {
			state.listByCategory = payload;
		},
		listSingleEntry(state, payload) {
			state.listSingleEntry = payload;
		},
		listSingleEntryWithHtml(state, payload) {
			state.listSingleEntryWithHtml = payload;
		},
		listSingleEntryByUrl(state, payload) {
			state.listSingleEntryByUrl = payload;
		},
		listAuthors(state, payload) {
			state.listAuthors = payload;
		},
		setAuthor(state, payload) {
			state.loggedInAuthor = payload;
			console.log(payload.id);
		},
		logoutAuthor(state) {
			localStorage.removeItem('token');
			localStorage.removeItem('author');
			state.token = null;
			router.push({ path: '/admin/login' });
		},
	},
	actions: {
		async listAllEntries(context) {
			try {
				fetch(`${process.env.VUE_APP_BACKEND_URL}/api/blog/entries`)
					.then((response) => response.json())
					.then((data) => {
						context.commit('listAllEntries', data);
					});
			} catch (err) {
				throw new Error(err);
			}
		},
		async listByCategory(context, categoryName) {
			try {
				fetch(
					`${process.env.VUE_APP_BACKEND_URL}/api/blog/categories/${categoryName}`
				)
					.then((response) => response.json())
					.then((data) => {
						console.log(data);
						context.commit('listByCategory', data);
					});
			} catch (err) {
				throw new Error(err);
			}
		},
		async listSingleEntry(context, entryId) {
			try {
				return fetch(
					`${process.env.VUE_APP_BACKEND_URL}/api/blog/entries/${entryId}`
				)
					.then((response) => response.json())
					.then((data) => {
						context.commit('listSingleEntry', data);
					});
			} catch (err) {
				throw new Error(err);
			}
		},
		async listSingleEntryWithHtml(context, entryId) {
			try {
				return fetch(
					`${process.env.VUE_APP_BACKEND_URL}/api/blog/entries/${entryId}`
				)
					.then((response) => response.json())
					.then((data) => {
						context.commit('listSingleEntryWithHtml', data);
					});
			} catch (err) {
				throw new Error(err);
			}
		},
		async listSingleEntryByUrl(context, entryUrl) {
			try {
				return fetch(
					`${process.env.VUE_APP_BACKEND_URL}/api/blog/posts/${entryUrl}`
				)
					.then((response) => response.json())
					.then((data) => {
						context.commit('listSingleEntryByUrl', data);
					});
			} catch (err) {
				throw new Error(err);
			}
		},
		async deleteEntry(context, entryId) {
			try {
				fetch(
					`${process.env.VUE_APP_BACKEND_URL}/api/admin/delete/entries/${entryId}`,
					{
						method: 'DELETE',
						headers: new Headers({
							Authorization:
								'Bearer ' + localStorage.getItem('token'),
						}),
					}
				)
					.then((response) => response.json())
					.then((data) => {
						console.log(data);
						// A törlést követően lekérdezzük ismételten a táblát,
						// ami performance szempontjából nem optimalis
						context.dispatch('listAllEntries');
					});
			} catch (err) {
				throw new Error(err);
			}
		},
		async publishEntry(context, entryDetails) {
			try {
				console.log(JSON.stringify(entryDetails));
				fetch(
					`${process.env.VUE_APP_BACKEND_URL}/api/admin/create/entry`,
					{
						method: 'POST',
						headers: new Headers({
							Authorization:
								'Token ' + localStorage.getItem('token'),
							'Content-Type': 'application/json',
						}),
						body: JSON.stringify(entryDetails),
					}
				)
					.then((response) => response.json())
					.then((data) => {
						console.log(data);
						router.push('/admin/dashboard/entries');
					});
			} catch (err) {
				throw new Error(err);
			}
		},
		async listAuthors(context) {
			try {
				return fetch(
					`${process.env.VUE_APP_BACKEND_URL}/api/blog/authors`
				)
					.then((response) => response.json())
					.then((data) => {
						context.commit('listAuthors', data);
					});
			} catch (err) {
				throw new Error(err);
			}
		},
		async updateEntry(context, entryDetails) {
			try {
				const updatedDetails = {
					id: entryDetails.id,
					title: entryDetails.title,
					category: entryDetails.category,
					labels: entryDetails.labels,
					content: entryDetails.content,
					author: entryDetails.author,
				};

				return fetch(
					`${process.env.VUE_APP_BACKEND_URL}/api/admin/edit/entries/${entryDetails.id}`,
					{
						method: 'PUT',
						headers: new Headers({
							Authorization:
								'Token ' + localStorage.getItem('token'),
							'Content-Type': 'application/json',
						}),
						body: JSON.stringify(updatedDetails),
					}
				)
					.then((response) => response.json())
					.then((data) => {
						console.log('Update is OK!');
						router.push('/admin/dashboard/entries');
					});
			} catch (err) {
				throw new Error(err);
			}
		},
		async authenticateAdmin(context, givenCredentials) {
			try {
				return fetch(
					`${process.env.VUE_APP_BACKEND_URL}/api/admin/sign-in`,
					{
						method: 'POST',
						headers: new Headers({
							'Content-Type': 'application/json',
						}),
						body: JSON.stringify(givenCredentials),
					}
				)
					.then((response) => response.json())
					.then((data) => {
						if (data.loggedInAuthor) {
							localStorage.setItem('token', data.token);
							localStorage.setItem(
								'author',
								data.loggedInAuthor.name
							);

							context.commit('setAuthor', data.loggedInAuthor);
							router.push({
								path: '/admin/dashboard',
							});
						} else {
							return 'Nem megfelelő e-mail vagy jelszó!';
						}
					});
			} catch (err) {
				throw new Error(err);
			}
		},
	},
});

export default store;
