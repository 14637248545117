<template>
	<div>
		<section>
			<div class="container">
				<div v-if="loading">
					<div
						class="row d-flex justify-content-center align-items-center"
					>
						<div class="col">
							Loading...
						</div>
					</div>
				</div>
				<div v-else>
					<div>
						<nav
							style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
							aria-label="breadcrumb"
						>
							<ol class="breadcrumb">
								<li class="breadcrumb-item">
									<router-link to="/admin/dashboard/entries"
										>Back to entries</router-link
									>
								</li>
								<li
									class="breadcrumb-item active"
									aria-current="page"
								>
									Edit entry
								</li>
							</ol>
						</nav>
					</div>
					<h1>Edit entry</h1>
					<hr />
					<div class="row">
						<div class="col-12">
							<div class="mb-3">
								<label for="entryTitle" class="form-label"
									>Title</label
								>
								<input
									v-model="singlePostInstance.title"
									type="text"
									class="form-control"
									id="entryTitle"
									placeholder="Input field for title..."
								/>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-6">
							<div class="mb-3">
								<label for="entryCategory" class="form-label"
									>Category</label
								>
								<input
									v-model="singlePostInstance.category"
									type="text"
									class="form-control"
									id="entryCategory"
									placeholder="Sport"
								/>
							</div>
						</div>
						<div class="col-6">
							<div class="mb-3">
								<label for="entryLabels" class="form-label"
									>Labels</label
								>
								<input
									v-model="singlePostInstance.labels"
									type="text"
									class="form-control"
									id="entryLabels"
									placeholder="innovation, football, academy"
								/>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<p class="small">
								Here you can update the category or labels,
								as well as add new labels. It is important
								that both fields should have a value.
								You can separate labels with a colon (,).
							</p>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<label for="entryContent2" class="form-label"
								>Content</label
							>

							<!-- eslint-disable -->
							<div id="editor">
								<QuillEditor
									contentType="html"
									v-model:content="singlePostInstance.content"
									:options="editorOptions"
									toolbar="full"
									theme="snow"
								/>
							</div>

							<!-- eslint-enable -->
						</div>
					</div>
					<div class="row mt-3 mb-5">
						<div class="col">
							<button
								@click="updateEntry()"
								type="submit"
								class="btn btn-primary btn-lg"
							>
								Publish!
							</button>
						</div>
					</div>
					<hr />
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
	setup() {},
	components: {
		QuillEditor,
	},
	data() {
		return {
			loading: true,
			entryCategoryValue: null,
			entryLabelsArray: [],
			writtenContent: null,
			entryId: null,
			singlePostInstance: null,
			contentHTML: 'asd',
			editorOptions: {},
		};
	},
	beforeCreate() {},
	beforeMount() {
		this.entryId = this.$route.params.entryId;
	},
	async mounted() {
		await this.$store.dispatch('listSingleEntryWithHtml', this.entryId);
		this.singlePostInstance = this.$store.getters.listSingleEntryWithHtml;
		this.loading = false;
	},
	methods: {
		// useCategory(event) {
		// 	this.entryCategoryValue = event.target.value;
		// 	this.entryCategory = `A blogbejegyzés a ${event.target.value} kategóriába fog kerülni.`;
		// },
		parseLabel() {
			// if (event.target.value != '') {
			// 	this.entryLabelsArray.push(event.target.value);
			// 	event.target.value = '';
			// 	if (this.entryLabelsArray.length !== 0) {
			// 		this.entryLabels = `A blogbejegyzés a(z) ${this.entryLabelsArray} címké(vel|kkel) lesz ellátva.`;
			// 	}
			// }
		},
		removeLabel(labelIndex) {
			// console.log(this.entryLabelsArray[labelIndex]);
			// console.log(labelIndex);
		},
		processWrittenContent(event) {
			// Jelenleg minden keystroke esetén emittálunk a child-ból, ami performance szempontból hibás.
			this.writtenContent = JSON.stringify(event);
		},
		updateEntry() {
			const entryDetails = {
				id: this.entryId,
				title: this.singlePostInstance.title,
				category: this.singlePostInstance.category,
				labels: this.singlePostInstance.labels, //this.entryLabelsArray.join(),
				author: 1,
				content: this.singlePostInstance.content,
			};
			this.$store.dispatch('updateEntry', entryDetails);
		},
	},
	computed: {},
};
</script>
<style scoped>
ol > .breadcrumb-item > a {
	color: #e6627e !important;
}
.ql-toolbar.ql-snow {
	border: none !important;
}
.ql-container.ql-snow {
	border: none !important;
}
#entryContent {
	border: 1px solid #ccc;
	border-radius: 10px;
}
.btn.btn-primary.btn-lg {
	background-color: #131132;
	border: 0px;
}
#editor {
	min-height: 400px;
	overflow-y: auto;
}
</style>
