<template>
	<Form v-slot="{ errors, meta }">
		<div class="container">
			<div class="row d-flex align-items-center mt-3">
				<div class="col-12 d-flex justify-content-center">
					<img src="../assets/logo.svg" alt="Sportlander logo" />
				</div>
			</div>
			<div class="row d-flex align-items-center justify-content-center">
				<div class="col-10  d-flex justify-content-center text-center">
					<div
						v-if="errorMsg"
						class="alert alert-secondary p-5"
						role="alert"
					>
						{{ errorMsg }}
					</div>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col">
					<div class="form-floating mb-3">
						<Field
							v-model="email"
							type="email"
							class="form-control"
							:class="{
								'is-valid': !errors.email && email,
								'is-invalid': errors.email,
							}"
							id="email"
							name="email"
							:rules="emailRules"
							:validateOnChange="true"
							placeholder="hello@sportlander.hu"
						/>
						<label for="email">E-mail</label>
					</div>

					<div class="form-floating">
						<Field
							v-model="pass"
							type="password"
							class="form-control"
							:class="{
								'is-valid': !errors.password && pass,
								'is-invalid': errors.password,
							}"
							id="password"
							name="password"
							:rules="passwordRules"
							:validationOnChange="true"
							placeholder="Password"
						/>
						<label for="password">Password</label>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<button
						@click="checkCredentials()"
						type="button"
						:disabled="!meta.valid"
						class="btn btn-secondary btn-lg d-grid mx-auto mt-4"
					>
						Login
					</button>
				</div>
			</div>
		</div>
	</Form>
</template>

<script>
import { Field, Form } from 'vee-validate';
import * as yup from 'yup';
export default {
	setup() {},
	components: {
		Field,
		Form,
	},
	data() {
		return {
			errorMsg: null,
			email: null,
			pass: null,
			emailRules: yup
				.string()
				.required()
				.email(),
			passwordRules: yup
				.string()
				.required()
				.min(5),
		};
	},
	computed: {},
	methods: {
		async checkCredentials() {
			this.errorMsg = await this.$store.dispatch('authenticateAdmin', {
				email: this.email,
				pass: this.pass,
			});
		},
	},
};
</script>

<style></style>
