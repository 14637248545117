<template>
	<div>
		<dashboard-component></dashboard-component>
		<section id="authors-section">
			<div class="container">
				<div>
					<nav
						style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
						aria-label="breadcrumb"
					>
						<ol class="breadcrumb">
							<li class="breadcrumb-item">
								<a href="#">Dashboard</a>
							</li>
							<li
								class="breadcrumb-item active"
								aria-current="page"
							>
								Authors
							</li>
						</ol>
					</nav>
				</div>
				<h1>Authors</h1>
				<hr />
				<div class="row">
					<div v-if="!loading">
						<div class="col-12">
							<div
								v-for="author in listOfAuthors"
								:key="author"
								class="row py-3 entry-list"
							>
								<div
									class="row my-5 d-flex align-items-center justify-content-center justify-content-md-start"
								>
									<div
										id="author-avatar"
										class="col-sm-12 col-md-4 col-lg-3 d-flex"
									>
										<img
											:src="author.avatar"
											class="card-img-top"
											alt="..."
										/>
									</div>
									<div
										class="col-sm-12 col-md-8 col-lg-9 d-flex align-items-center justify-content-center justify-content-md-start"
									>
										<div
											class="d-flex flex-column align-items-center align-items-md-start mt-3 mt-md-0"
										>
											<div>
												<a
													:href="author.social"
													target="_blank"
													><span class="h4 py-2">{{
														author.name
													}}</span></a
												>
											</div>
											<div>
												<p class="lead text-center">
													{{ author.description }}
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						v-else
						id="loading-block"
						class="d-flex justify-content-center align-items-center pt-5"
					>
						<div
							class="spinner-border d-flex"
							style="width: 5rem; height: 5rem;"
							role="status"
						>
							<span class="visually-hidden">Loading...</span>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div class="container-fluid">
			<div class="row">
				<footer-component></footer-component>
			</div>
		</div>
	</div>
</template>

<script>
// import { ref, defineComponent } from 'vue';
// import { QuillEditor, Delta } from '@vueup/vue-quill';
// import '@vueup/vue-quill/dist/vue-quill.snow.css';
// import '@vueup/vue-quill/dist/vue-quill.bubble.css';
export default {
	setup() {},
	data() {
		return {
			loading: true,
			listOfAuthors: null,
		};
	},
	computed: {},
	async mounted() {
		await this.$store.dispatch('listAuthors');
		this.listOfAuthors = this.$store.getters.listAuthors;
		this.loading = false;
	},
	created() {
		console.log(this.listOfAuthors);
	},
};
</script>

<style scoped>
#authors-section {
	min-height: 65vh;
}
.entry-list:nth-child(2n) {
	background-color: #dadada9d;
	border-radius: 15px;
}
#author-avatar {
	width: 10rem;
}
#author-avatar-small {
	width: 3rem;
}
#author-avatar-small > img {
	border-radius: 50%;
}
#author-avatar > img {
	border-radius: 50%;
}
a:any-link {
	color: #e6627e;
	text-decoration: none;
}
</style>
