<template>
	<header>
		<div>
			<nav class="navbar navbar-light">
				<div
					class="container-fluid d-flex justify-content-center justify-content-md-between
"
				>
					<a class="navbar-brand" href="#">
						<img
							src="../assets/sm-logo.png"
							alt=""
							width="150"
							class="d-inline-block align-text-center"
						/>
						<span style="color: white; margin-left: .75rem;">SmartGoals</span>

						<sub
							><span class="small" style="color: white"
								>&lt;blogEngine/&gt;</span
							></sub
						>
					</a>
					<div class="d-flex">
						<div class="dropdown">
							<span class="text-muted">Hello,</span>
							&nbsp;
							<a
								class="dropdown-toggle"
								href="#"
								role="button"
								id="authorMenuDropdown"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								{{ loggedInUser.name }}
							</a>

							<ul
								class="dropdown-menu dropdown-menu-lg-end"
								aria-labelledby="authorMenuDropdown"
							>
								<li>
									<a
										@click="logoutAuthor()"
										class="dropdown-item"
										href="#"
										>Kijelentkezés</a
									>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</nav>
		</div>
	</header>
</template>

<script>
export default {
	setup() {},
	mounted() {},
	data() {
		return {
			userName: null,
		};
	},
	computed: {
		loggedInUser() {
			this.userName = this.$store.getters.loggedInAuthor;
			return this.userName;
		},
	},
	methods: {
		logoutAuthor() {
			this.$store.commit('logoutAuthor');
		},
	},
};
</script>

<style scoped>
header {
	color: #131132;
	background-image: url('../assets/header.svg');
	background-position-y: top;
	background-size: cover;
	width: 100%;
	margin: 0;
	background-repeat: no-repeat;
	height: 13rem;
}
a > .navbar-brand {
	color: white !important;
}
.dropdown > a:any-link {
	color: #e6627e;
	outline: none;
}
</style>
